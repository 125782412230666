import {APP_ID, APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {Router, RouteReuseStrategy} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxsModule} from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import {TraceService} from '@sentry/angular-ivy';
import {NgxsRouterPluginModule, RouterStateSerializer} from '@ngxs/router-plugin';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {CookieService} from 'ngx-cookie-service';
import {NgxEchartsModule} from 'ngx-echarts';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {PagesModule} from './pages/pages.module';
import {HomeModule} from './home/home.module';
import {CSRFInterceptor} from './interceptors/csrf-interceptor';
import {DataParamsRouterStateSerializer} from './rt-route-state-serializer/serializers';
import {AuthState} from './auth/states/auth/auth.state';
import {AuthErrorInterceptor} from './auth/interceptors/auth-error/auth-error.interceptor';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {CookiesModule} from './shared/microservices/cookies/cookies.module';
import {WindowModule} from './universal/window/window.module';
import {RtDynamicScriptsModule} from './rt-dynamic-scripts/rt-dynamic-scripts.module';
import {ConnectionServiceOptions, ConnectionServiceOptionsToken} from './rt-connection/rt-connection.service';
import {RtLoadProgressModule} from './rt-load-progress/rt-load-progress.module';
import {Settings} from '../conf/settings.base';
import {environment, settings} from '../environments/environment';
import {RtTitlesModule} from './rt-titles/rt-titles.module';
import {RtTrackUserEventsModule} from './rt-track-user-events/rt-track-user-events.module';
import {formatTitle} from './shared/functions/format-title';
import {MicroservicesModule} from './shared/microservices/microservices.module';
import {JwtTokenInterceptor} from './rt-jwt-auth/interceptor/jwt-token.interceptor';
import {CustomRouteReuseStrategy} from './shared/classes/custom-route-reuse-strategy';
import {RtHammerJsModule} from './rt-hammer-js/rt-hammer-js.module';
import {RtRouterDataResolverState} from './rt-router-data-resolver/rt-router-data-resolver.state';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';

const PROVIDERS: any[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CSRFInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtTokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthErrorInterceptor,
    multi: true,
  },
  {
    provide: RouteReuseStrategy,
    useClass: CustomRouteReuseStrategy,
  },
  {provide: APP_ID, useValue: 'brain-key-app'},
  {provide: Settings, useValue: settings},
  {provide: RouterStateSerializer, useClass: DataParamsRouterStateSerializer},
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '188537416586-pqmtkfajdo04rf87quk668i27unfej52.apps.googleusercontent.com'
          ),
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider('4570321899659112')
        }
      ],
    } as SocialAuthServiceConfig,
  },
  {
    provide: ConnectionServiceOptionsToken,
    useValue: {
      enableHeartbeat: false,
      heartbeatInterval: 30000,
    } as ConnectionServiceOptions,
  },
  CookieService,
  provideClientHydration(),
];


if (environment.production) {
  PROVIDERS.push(
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  );
}


@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxsModule.forRoot([AuthState, RtRouterDataResolverState], {developmentMode: !environment.production}),
    NgxsRouterPluginModule.forRoot(),
    RtHammerJsModule,
    AppRoutingModule,
    WindowModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule.forRoot({duration: 250}),
    MatSnackBarModule,
    SocialLoginModule,
    RtDynamicScriptsModule.forRoot({
      scripts: [
        {name: 'papaya', src: 'assets/scripts/papaya.js'}
      ]
    }),
    HomeModule,
    CookiesModule.forRoot({EXCLUDE_ROUTES_LIST: ['demo-viewer']}),
    PagesModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    RtLoadProgressModule.forRoot({
      PROGRESS_MIN_STEP: 10,
      NUMBER_OF_SYMBOLS_AFTER_COMMA: 0,
    }),
    RtTitlesModule.forRoot({
      PAGE_TITLE: settings.TITLE_DEFAULT,
      TITLE_PAGE_SEPARATOR: settings.TITLE_SEPARATOR,
      META_TAGS: settings.META_DEFAULT,
      TITLE_FORMATTER: formatTitle,
    }),
    RtTrackUserEventsModule.forRoot({
      TITLE_FORMATTER: formatTitle,
    }),
    MicroservicesModule,
  ],
  providers: PROVIDERS,
})
export class AppModule {
}
